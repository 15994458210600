<template>
  <div v-if="showBanner" class="columns is-mobile is-marginless has-background-link-light has-text-link">
    <div class="column is-narrow pr-0">
      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#196c85"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg>
    </div>
    <div class="column">
      <p><b>Scheduled maintenance work on Tuesday 31st August 2021 from 09:00 AM to 1:00 PM (BST)</b></p>
      <p style="font-size: 0.813rem;">Search results may be incomplete while we reload the latest data in the background. We apologise for any inconvenience this might cause and thank you for your patience.</p>
    </div>
    <div class="column is-narrow">
      <button type="button" class="delete" @click="hideNotification" aria-label="Hide notification"></button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MaintenanceBanner',
  data() {
    return {
      showBanner: new Date() < new Date(2021, 7, 31, 13, 0, 0),
    }
  },
  methods: {
    hideNotification() {
      this.showBanner = false;
    },
  },
}
</script>
