<template>
  <router-link to="/login" id="btn-toolbar-log-in" class="button is-info pr-2 mb-2 has-text-weight-bold">
    Sign in
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="28" height="28" class="level-item" aria-hidden="true">
      <path d="M0 0h24v24H0V0z" fill="none"/>
      <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"/>
    </svg>
  </router-link>
</template>

<script>
export default {
  name: 'LoginButton',
}
</script>
