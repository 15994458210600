<template>

  <div class="columns is-gapless">

    <div class="column is-one-quarter search-types classifications">
      <div id="search-types-functional" class="pt-2 pb-4">
        <ul class="ml-4" role="tablist">
          <li v-for="(tab, index) in tabs" :key="index">
            <span v-if="tab.heading" class="is-block mt-4 mb-1 has-text-grey">
              {{ tab.heading }}
            </span>
            <a v-else
              href @click.prevent="switchTab(index)"
              class="py-2 px-4"
              role="tab"
              :aria-selected="currentTab === index"
              :aria-controls="`functional-panel-${index}`"
              :class="{ 'active': currentTab === index }"
              :id="`functional-tab-${index}`"
              v-html="tab.title">
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="column functional-group"
      tabindex="-1"
      role="tabpanel"
      ref="functionalForm"
      aria-expanded="true"
      :id="`functional-panel-${currentTab}`"
      :aria-labelledby="`functional-tab-${currentTab}`">

      <div id="functional-group-container" class="px-5 py-5">

        <p class="search-panel-guidance mb-5">
          Insert number of each type of functional group.
          To exclude a functional group, set the number to 0.
          Fields left blank will be included in matches.
        </p>

        <!------------------------------------>
        <!-- 0. CARBON SKELETON (tab group) -->
        <!------------------------------------>
        
        <!-- 1. sp3 carbons -->
        <fieldset v-show="currentTab === 1">
          <legend class="is-sr-only" v-html="tabs[1].title"></legend>
          <div class="columns is-multiline">
            <TextInput id="met" rules="number|integer|non_negative" class="column is-full" label="Total methyl groups (includes methoxyl. <i>N</i>-methyl, <i>S</i>-methyl and acetyl)" v-model.trim="met" @input="updateSearch"/>
            <TextInput id="mets" rules="number|integer|non_negative" class="column is-one-third" unbold label="Singlet methyl <span class='is-block-tablet'>(R<sub>3</sub>C-CH<sub>3</sub>)</span>" v-model.trim="mets" @input="updateSearch"/>
            <TextInput id="metd" rules="number|integer|non_negative" class="column is-one-third" unbold label="Doublet methyl <span class='is-block-tablet'>(R<sub>2</sub>HC-CH<sub>3</sub>)</span>" v-model.trim="metd" @input="updateSearch"/>
            <TextInput id="mett" rules="number|integer|non_negative" class="column is-one-third" unbold label="Triplet methyl <span class='is-block-tablet'>(RH<sub>2</sub>C-CH<sub>3</sub>)</span>" v-model.trim="mett" @input="updateSearch"/>
            <TextInput id="arome" rules="number|integer|non_negative" class="column is-one-third" unbold label="Aromatic <span class='is-block-tablet'>methyl</span>" v-model.trim="arome" @input="updateSearch"/>
            <TextInput id="vnme" rules="number|integer|non_negative" class="column is-one-third" unbold label="Vinyl methyl <span class='is-block-tablet'>(C=CH-CH<sub>3</sub>)</span>" v-model.trim="vnme" @input="updateSearch"/>
            <TextInput id="acy" rules="number|integer|non_negative" class="column is-one-third" unbold label="Acetyl (including acetyl ester &amp; acetyl amide)" v-model.trim="acy" @input="updateSearch"/>
            <TextInput id="meo" rules="number|integer|non_negative" class="column is-one-third" unbold label="Methoxy <span class='is-block-tablet'>(-OCH<sub>3</sub>)</span>" v-model.trim="meo" @input="updateSearch"/>
            <TextInput id="men" rules="number|integer|non_negative" class="column is-one-third" unbold label="N-methyl <span class='is-block-tablet'>(-NCH<sub>3</sub>)</span>" v-model.trim="men" @input="updateSearch"/>
            <TextInput id="mes" rules="number|integer|non_negative" class="column is-one-third" unbold label="S-methyl <span class='is-block-tablet'>(-SCH<sub>3</sub>)</span>" v-model.trim="mes" @input="updateSearch"/>
          </div>
          <hr class="my-5" />
          <div class="columns is-multiline">  
            <TextInput id="mene" rules="number|integer|non_negative" class="column is-one-third" label="Total methylene (secondary) carbons" v-model.trim="mene" @input="updateSearch"/>
            <TextInput id="mine" rules="number|integer|non_negative" class="column is-one-third" label="Total methine (tertiary) carbons" v-model.trim="mine" @input="updateSearch"/>
          </div>
        </fieldset>

        <!-- 2. sp2 carbons -->
        <fieldset v-show="currentTab === 2">
          <legend class="is-sr-only" v-html="tabs[2].title"></legend>
          <div class="columns is-multiline">
            <TextInput id="sp2h" rules="number|integer|non_negative" class="column is-full" label="Total sp<sup>2</sup>C–H" v-model.trim="sp2h" @input="updateSearch"/>
          </div>
          <hr class="my-5" />
          <div class="columns is-multiline">
            <TextInput id="cc" rules="number|integer|non_negative" class="column is-full" label="Total C=C (excludes benzene and pyridine rings)" v-model.trim="cc" @input="updateSearch"/>
            <TextInput id="vn" rules="number|integer|non_negative" class="column is-full" unbold label="Vinyl group (H<sub>2</sub>C=CH–)" v-model.trim="vn" @input="updateSearch"/>
            <TextInput id="cch" rules="number|integer|non_negative" class="column is-one-third" unbold label="1,1-disubstituted" v-model.trim="cch" @input="updateSearch"/>
            <TextInput id="dic" rules="number|integer|non_negative" class="column is-two-thirds" unbold label="1,2-disubstituted" v-model.trim="dic" @input="updateSearch"/>
            <TextInput id="cc3" rules="number|integer|non_negative" class="column is-one-third" unbold label="Trisubstituted" v-model.trim="cc3" @input="updateSearch"/>
          </div>
        </fieldset>

        <!-- 3. sp carbons -->
        <fieldset v-show="currentTab === 3">
          <legend class="is-sr-only" v-html="tabs[3].title"></legend>
          <TextInput id="tlk" rules="number|integer|non_negative" label="Total terminal alkynes (–C≡C–H)" v-model.trim="tlk" @input="updateSearch"/>
        </fieldset>

        <!------------------------------------>
        <!--   4. HETEROATOMS (tab group)   -->
        <!------------------------------------>

        <!-- 5. C–O bonds -->
        <fieldset v-show="currentTab === 5">
          <legend class="is-sr-only" v-html="tabs[5].title"></legend>
          <div class="columns is-multiline">
            <TextInput id="cao" rules="number|integer|non_negative" class="column is-one-third" label="Total C-O (excluding acids, esters and lactones)" v-model.trim="cao" @input="updateSearch" @focus="showCaoHelp = true" @blur="showCaoHelp = false"/>
            <div class="column is-hidden-mobile is-two-thirds is-relative">
              <HelpBubble :class="{ 'is-hidden': !showCaoHelp }" helpText="Excludes C–O bonds from the sp2 carbon in acids, lactones and esters. Ethers count as 2 C–O bonds. Lactones count as 1 C–O bond."/>
            </div>
            <TextInput id="c2ho" rules="number|integer|non_negative" class="column is-one-third" unbold label="Primary C-O <span class='is-block-tablet'>(-CH<sub>2</sub>-O-)</span></span>" v-model.trim="c2ho" @input="updateSearch"/>
            <TextInput id="c1ho" rules="number|integer|non_negative" class="column is-two-thirds" unbold label="Secondary C-O <span class='is-block-tablet'>(&gt;CH-O-)</span></span>" v-model.trim="c1ho" @input="updateSearch"/>
            <TextInput id="ch2o2" rules="number|integer|non_negative" class="column is-one-third" unbold label="Primary acetal" v-model.trim="ch2o2" @input="updateSearch"/>
            <TextInput id="cho2" rules="number|integer|non_negative" class="column is-one-third" unbold label="Secondary acetal" v-model.trim="cho2" @input="updateSearch"/>
            <TextInput id="co2" rules="number|integer|non_negative" class="column is-one-third" unbold label="Tertiary acetal" v-model.trim="co2" @input="updateSearch"/>
          </div>
        </fieldset>

        <!-- 6. C=O bonds -->
        <fieldset v-show="currentTab === 6">
          <legend class="is-sr-only" v-html="tabs[6].title"></legend>
          <div class="columns is-multiline">
            <TextInput id="co" rules="number|integer|non_negative" class="column is-full" label="Total C=O" v-model.trim="co" @input="updateSearch"/>
            <TextInput id="cho" rules="number|integer|non_negative" class="column is-one-third" unbold label="Aldehyde (including formyl)" v-model.trim="cho" @input="updateSearch"/>
            <TextInput id="aco" rules="number|integer|non_negative" class="column is-one-third" unbold label="Acid, ester, lactone" v-model.trim="aco" @input="updateSearch"/>
            <TextInput id="am" rules="number|integer|non_negative" class="column is-one-third" unbold label="Amide" v-model.trim="am" @input="updateSearch"/>
          </div>
        </fieldset>

        <!-- 7. C=N & C≡N bonds -->
        <fieldset v-show="currentTab === 7">
          <legend class="is-sr-only" v-html="tabs[7].title"></legend>
          <div class="columns is-multiline">
            <TextInput id="cn" rules="number|integer|non_negative" class="column is-one-third" label="Imine" v-model.trim="cn" @input="updateSearch"/>
            <TextInput id="nit" rules="number|integer|non_negative" class="column is-one-third" label="Nitrile" v-model.trim="nit" @input="updateSearch"/>
            <TextInput id="initr" rules="number|integer|non_negative" class="column is-one-third" label="Isonitrile" v-model.trim="initr" @input="updateSearch"/>
          </div>
        </fieldset>

        <!------------------------------------>
        <!--      8. RINGS (tab group)      -->
        <!------------------------------------>

        <!-- 9. Benzene rings -->
        <fieldset v-show="currentTab === 9">
          <legend class="is-sr-only" v-html="tabs[9].title"></legend>
          <div class="columns is-multiline">
            <TextInput id="ben" rules="number|integer|non_negative" class="column is-full" label="Total benzenes" v-model.trim="ben" @input="updateSearch"/>
            <TextInput id="b1" rules="number|integer|non_negative" class="column is-full" unbold label="1-substituted" v-model.trim="b1" @input="updateSearch"/>
            <TextInput id="b12" rules="number|integer|non_negative" class="column is-one-third" unbold label="1,2-substituted" v-model.trim="b12" @input="updateSearch"/>
            <TextInput id="b13" rules="number|integer|non_negative" class="column is-one-third" unbold label="1,3-substituted" v-model.trim="b13" @input="updateSearch"/>
            <TextInput id="b14" rules="number|integer|non_negative" class="column is-one-third" unbold label="1,4-substituted" v-model.trim="b14" @input="updateSearch"/>
            <TextInput id="b123" rules="number|integer|non_negative" class="column is-one-third" unbold label="1,2,3-substituted" v-model.trim="b123" @input="updateSearch"/>
            <TextInput id="b124" rules="number|integer|non_negative" class="column is-one-third" unbold label="1,2,4-substituted" v-model.trim="b124" @input="updateSearch"/>
            <TextInput id="b135" rules="number|integer|non_negative" class="column is-one-third" unbold label="1,3,5-substituted" v-model.trim="b135" @input="updateSearch"/>
            <TextInput id="b1234" rules="number|integer|non_negative" class="column is-one-third" unbold label="1,2,3,4-substituted" v-model.trim="b1234" @input="updateSearch"/>
            <TextInput id="b1235" rules="number|integer|non_negative" class="column is-one-third" unbold label="1,2,3,5-substituted" v-model.trim="b1235" @input="updateSearch"/>
            <TextInput id="b1245" rules="number|integer|non_negative" class="column is-one-third" unbold label="1,2,4,5-substituted" v-model.trim="b1245" @input="updateSearch"/>
            <TextInput id="b12345" rules="number|integer|non_negative" class="column is-one-third" unbold label="1,2,3,4,5-substituted" v-model.trim="b12345" @input="updateSearch"/>
            <TextInput id="b1_6" rules="number|integer|non_negative" class="column is-one-third" unbold label="1,2,3,4,5,6-substituted" v-model.trim="b1_6" @input="updateSearch"/>
          </div>
        </fieldset>

        <!-- 10. Pyridine rings -->
        <fieldset v-show="currentTab === 10">
          <legend class="is-sr-only" v-html="tabs[10].title"></legend>
          <div class="columns is-multiline">
            <TextInput id="p2" rules="number|integer|non_negative" class="column is-one-third" unbold label="2-substituted" v-model.trim="p2" @input="updateSearch"/>
            <TextInput id="p3" rules="number|integer|non_negative" class="column is-one-third" unbold label="3-substituted" v-model.trim="p3" @input="updateSearch"/>
            <TextInput id="p4" rules="number|integer|non_negative" class="column is-one-third" unbold label="4-substituted" v-model.trim="p4" @input="updateSearch"/>
            <TextInput id="p23" rules="number|integer|non_negative" class="column is-one-third" unbold label="2,3-substituted" v-model.trim="p23" @input="updateSearch"/>
            <TextInput id="p24" rules="number|integer|non_negative" class="column is-one-third" unbold label="2,4-substituted" v-model.trim="p24" @input="updateSearch"/>
            <TextInput id="p25" rules="number|integer|non_negative" class="column is-one-third" unbold label="2,5-substituted" v-model.trim="p25" @input="updateSearch"/>
            <TextInput id="p26" rules="number|integer|non_negative" class="column is-one-third" unbold label="2,6-substituted" v-model.trim="p26" @input="updateSearch"/>
            <TextInput id="p34" rules="number|integer|non_negative" class="column is-one-third" unbold label="3,4-substituted" v-model.trim="p34" @input="updateSearch"/>
            <TextInput id="p35" rules="number|integer|non_negative" class="column is-one-third" unbold label="3,5-substituted" v-model.trim="p35" @input="updateSearch"/>
            <TextInput id="p234" rules="number|integer|non_negative" class="column is-one-third" unbold label="2,3,4-substituted" v-model.trim="p234" @input="updateSearch"/>
            <TextInput id="p235" rules="number|integer|non_negative" class="column is-one-third" unbold label="2,3,5-substituted" v-model.trim="p235" @input="updateSearch"/>
            <TextInput id="p236" rules="number|integer|non_negative" class="column is-one-third" unbold label="2,3,6-substituted" v-model.trim="p236" @input="updateSearch"/>
            <TextInput id="p245" rules="number|integer|non_negative" class="column is-one-third" unbold label="2,4,5-substituted" v-model.trim="p245" @input="updateSearch"/>
            <TextInput id="p246" rules="number|integer|non_negative" class="column is-one-third" unbold label="2,4,6-substituted" v-model.trim="p246" @input="updateSearch"/>
            <TextInput id="p345" rules="number|integer|non_negative" class="column is-one-third" unbold label="3,4,5-substituted" v-model.trim="p345" @input="updateSearch"/>
            <TextInput id="p2345" rules="number|integer|non_negative" class="column is-one-third" unbold label="2,3,4,5-substituted" v-model.trim="p2345" @input="updateSearch"/>
            <TextInput id="p2346" rules="number|integer|non_negative" class="column is-one-third" unbold label="2,3,4,6-substituted" v-model.trim="p2346" @input="updateSearch"/>
            <TextInput id="p2356" rules="number|integer|non_negative" class="column is-one-third" unbold label="2,3,5,6-substituted" v-model.trim="p2356" @input="updateSearch"/>
            <TextInput id="p2_6" rules="number|integer|non_negative" class="column is-one-third" unbold label="2,3,4,5,6-substituted" v-model.trim="p2_6" @input="updateSearch"/>
          </div>
        </fieldset>

      </div>

    </div>

  </div>

</template>

<script>
import { mapFields } from 'vuex-map-fields';
import SearchPanelMixin from '@/mixins/search-panel-mixin';
export default {
  name: 'TabFunctional',
  mixins: [SearchPanelMixin],
  data: function() {
    return {
      currentTab: 1,
      tabs: [
        { heading: 'Carbon skeleton' },
          { title: 'sp<sup>3</sup> carbons' },
          { title: 'sp<sup>2</sup> carbons' },
          { title: 'sp carbons' },
        { heading: 'Heteroatoms' },
          { title: 'C–O bonds' },
          { title: 'C=O bonds' },
          { title: 'C=N &amp; C≡N bonds' },
        { heading: 'Rings' },
          { title: 'Benzene rings' },
          { title: 'Pyridine rings' },
      ],
      showCaoHelp: false,
    }
  },
  methods: {
    switchTab(index) {
      this.currentTab = index;
      // Move focus into form whenever a tab is selected, so that
      // keyboard users don't have to tab through secondary tabs to
      // reach the form. A short wait ensures that the correct form
      // is announced rather than the previously-visible form.
      setTimeout(() => this.$refs.functionalForm.focus(), 100);
    },
  },
  computed: {
    ...mapFields('search', {
      // sp3 carbons
      met: 'SearchParams.met.value',
      mets: 'SearchParams.mets.value',
      metd: 'SearchParams.metd.value',
      mett: 'SearchParams.mett.value',
      arome: 'SearchParams.arome.value',
      vnme: 'SearchParams.vnme.value',
      acy: 'SearchParams.acy.value',
      meo: 'SearchParams.meo.value',
      men: 'SearchParams.men.value',
      mes: 'SearchParams.mes.value',
      mene: 'SearchParams.mene.value',
      mine: 'SearchParams.mine.value',
      // sp2 carbons
      sp2h: 'SearchParams.sp2h.value',
      cc: 'SearchParams.cc.value',
      vn: 'SearchParams.vn.value',
      cch: 'SearchParams.cch.value',
      dic: 'SearchParams.dic.value',
      cc3: 'SearchParams.cc3.value',
      // sp carbons
      tlk: 'SearchParams.tlk.value',
      // C–O bonds
      cao: 'SearchParams.cao.value',
      c2ho: 'SearchParams.c2ho.value',
      c1ho: 'SearchParams.c1ho.value',
      ch2o2: 'SearchParams.ch2o2.value',
      cho2: 'SearchParams.cho2.value',
      co2: 'SearchParams.co2.value',
      // C=O bonds
      co: 'SearchParams.co.value',
      cho: 'SearchParams.cho.value',
      aco: 'SearchParams.aco.value',
      am: 'SearchParams.am.value',
      // C=N & C≡N bonds
      cn: 'SearchParams.cn.value',
      nit: 'SearchParams.nit.value',
      initr: 'SearchParams.initr.value',
      // Benzene rings
      ben: 'SearchParams.ben.value',
      b1: 'SearchParams.b1.value',
      b12: 'SearchParams.b12.value',
      b13: 'SearchParams.b13.value',
      b14: 'SearchParams.b14.value',
      b123: 'SearchParams.b123.value',
      b124: 'SearchParams.b124.value',
      b135: 'SearchParams.b135.value',
      b1234: 'SearchParams.b1234.value',
      b1235: 'SearchParams.b1235.value',
      b1245: 'SearchParams.b1245.value',
      b12345: 'SearchParams.b12345.value',
      b1_6: 'SearchParams.b1_6.value',
      // Pyridine rings
      p2: 'SearchParams.p2.value',
      p3: 'SearchParams.p3.value',
      p4: 'SearchParams.p4.value',
      p23: 'SearchParams.p23.value',
      p24: 'SearchParams.p24.value',
      p25: 'SearchParams.p25.value',
      p26: 'SearchParams.p26.value',
      p34: 'SearchParams.p34.value',
      p35: 'SearchParams.p35.value',
      p234: 'SearchParams.p234.value',
      p235: 'SearchParams.p235.value',
      p236: 'SearchParams.p236.value',
      p245: 'SearchParams.p245.value',
      p246: 'SearchParams.p246.value',
      p345: 'SearchParams.p345.value',
      p2345: 'SearchParams.p2345.value',
      p2346: 'SearchParams.p2346.value',
      p2356: 'SearchParams.p2356.value',
      p2_6: 'SearchParams.p2_6.value',
    }),
  },
}
</script>
