import {
  SET_LOGGED_IN_AS,
  SET_IP_AUTHENTICATION_ERROR,
  PURGE_AUTH,
} from '../mutation-types';
import { dataService } from '@/api';
import router from '@/router';

export default {

  namespaced: true,

  // STATE
  state: {
    loggedInAs: '',
    isAuthenticated: false,
    isAuthenticatedByIpAddress: false,
    ipAuthenticationError: '',
  },

  // MUTATIONS
  mutations: {
    [SET_LOGGED_IN_AS](state, payload) {
      state.loggedInAs = payload.name;
      state.isAuthenticated = true;
      state.isAuthenticatedByIpAddress = payload.isIpAuth;
      state.ipAuthenticationError = '';
    },
    [SET_IP_AUTHENTICATION_ERROR](state, payload) {
      state.ipAuthenticationError = payload;
    },
    [PURGE_AUTH](state) {
      state.loggedInAs = '';
      state.isAuthenticated = false;
      state.isAuthenticatedByIpAddress = false;
      state.ipAuthenticationError = '';
    },
  },

  // ACTIONS
  actions: {
    async startup({ dispatch }) {
      // Load home page data and auth status (based on JWT cookie) into Vuex stores
      const boot = await dataService.boot();
      if (!boot.ServiceStatus.HasError && boot.Data) {
        dispatch('compounds/initialiseLastUpdatedDate', boot.Data.lastUpdated, { root: true });
        dispatch('compounds/initialiseTotalNumberOfCompounds', boot.Data.totalNumberOfCompounds, { root: true });
        dispatch('articles/initialiseTotalNumberOfArticles', boot.Data.totalNumberOfArticles, { root: true });
        
        // Does the user already have a valid JWT?
        if (boot.Data.isLoggedIn) {
          dispatch('isLoggedInAs', boot.Data);
        }
        // If not, try IP authentication
        else {
          await dispatch('authenticateWithIpAddress');
        }
      }
      else {
        // Boot API request failed, so don't mount the Vue application at all
        throw boot.ServiceStatus.Error;
      }
    },
    async authenticateWithIpAddress({ commit, dispatch }) {
      const auth = await dataService.authenticate();
      if (auth.Data && auth.Data.isLoggedIn) {
        dispatch('isLoggedInAs', auth.Data);
      }
      else if (auth.ServiceStatus.HasError) {
        // Auth API failed, show an error message in case IP-authorised
        // customers encounter this and need to show us
        commit(SET_IP_AUTHENTICATION_ERROR, auth.ServiceStatus.Error);
      }
    },
    isLoggedInAs({ commit }, data) {
      commit(SET_LOGGED_IN_AS, {
        name: data.loggedInAs,
        isIpAuth: data.isAuthenticatedByIpAddress
      });
    },
    async logout({ commit, dispatch }, redirect) {
      // Hide the search panel in case logout was caused by JWT expiry
      dispatch('search/setSearchPanelVisibilityAction', false, { root: true });
      
      // Delete JWT cookie then reset Vuex auth module whatever the response
      await dataService.logout().then(() => {
        commit(PURGE_AUTH);
        router.push({ path: '/login', query: { redirect } });
      });
    },
  },

  // GETTERS
  getters: {
    loggedInAs: state => state.loggedInAs,
    isAuthenticated: state => state.isAuthenticated,
    isAuthenticatedByIpAddress: state => state.isAuthenticatedByIpAddress,
    ipAuthenticationError: state => state.ipAuthenticationError,
  },

}
