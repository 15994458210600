<template>
  <div class="content px-4 py-5">
    <div class="message is-danger" role="alert">
      <div class="message-body">
        <p class="is-size-4 mb-3">
          <b>Unable to retrieve results</b>
        </p>
        <p>
          There was a problem communicating with the server. Please check your network or Wi-Fi connection.
        </p>
        <p v-if="isDevEnvironment && error">
          {{ error }}
        </p>
        <p> 
          <button type="button" class="button" @click="retry">Try again</button>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServiceErrorMessage',
  props: {
    error: {
      type: Error,
      default: null,
    },
  },
  computed: {
    isDevEnvironment() {
      return process.env.NODE_ENV === 'development';
    },
  },
  methods: {
    retry() {
      this.$emit('retry');
    },
  },
}
</script>
