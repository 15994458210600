<template>
  <fieldset id="fieldset-nmr" class="px-5 py-5">

    <!-- Required by https://www.w3.org/TR/WCAG20-TECHS/H71.html -->
    <legend class="is-sr-only">{{ legend }}</legend>
    
    <!-- 13C NMR -->
    <h3 class="has-text-grey mb-4 is-size-5">
      <sup>13</sup>C NMR data
    </h3>
    <div class="columns mb-6">
      <div id="nmr-form-carbon" class="column">
        <div class="columns is-mobile mb-0">
          <label for="cNmr0" class="label column is-7-mobile is-8-tablet is-8-desktop is-7-widescreen pb-0">
            <span class="is-sr-only">13C</span> Chemical shift <span class="is-sr-only"> value row 1</span>
          </label>
          <label for="cNmrProtons0" class="label column pb-0">
            <span class="is-sr-only">13C Chemical shift</span> Attached protons <span class="is-sr-only">row 1</span>
          </label>
        </div>
        <div class="columns is-mobile mb-0" v-for="(cNmr, index) in carbonNmr" :key="index">
          <div class="column is-7-mobile is-8-tablet is-8-desktop is-7-widescreen">
            <label v-if="index > 0" class="is-sr-only" :for="`cNmr${index}`">
              13C Chemical shift value row {{ index + 1 }}
            </label>
            <div class="field has-addons mb-0">
              <TextInput
                class="control"
                rules="number"
                :id="`cNmr${index}`"
                v-model.trim="cNmr.value"
                @input="updateSearch"/>
              <div class="control">
                <label class="button is-static" :for="`cNmrVariance${index}`">
                  <span class="is-sr-only">13C Chemical shift</span> ± <span class="is-sr-only">variance row {{ index + 1 }}</span>
                </label>
              </div>
              <TextInput
                class="control"
                rules="number|non_negative"
                :id="`cNmrVariance${index}`"
                v-model.trim="cNmr.variance"
                v-on="cNmr.value.length ? { input: updateSearch } : {}"/>
              <div class="control carbon-nmr-ppm-label">
                <span class="button is-static">ppm</span>
              </div>
            </div>
          </div>
          <div class="column is-3-tablet">
            <div class="select">
              <label v-if="index > 0" class="is-sr-only" :for="`cNmrProtons${index}`">
                13C Chemical shift Attached protons row {{ index + 1 }}
              </label>
              <select :id="`cNmrProtons${index}`"
                v-model="cNmr.protons"
                v-on="cNmr.value.length ? { change: updateSearch } : {}">
                <option value="">Any</option>
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </div>
          </div>
          <AddRemoveRowButtons
            class="column is-narrow"
            :rowIndex="index"
            parameterId="carbonNmr"
            addLabel="Add more 13C NMR chemical shifts"
            :removeLabel="removeNmrLabel(cNmr.value, cNmr.variance, cNmr.protons, index)"
            @updateSearch="updateSearch"
          />
        </div>
      </div>
      <div class="column is-hidden-touch is-4-desktop is-relative">
        <HelpBubble
          class="is-absolute"
          style="top: 0.125rem;"
          helpTitle="NMR data"
          helpText="Search calculated NMR shift data. To add more values, 
                    press the + button. Variance can be left blank."/>
      </div>
    </div>

    <!-- 1H NMR -->
    <h3 class="has-text-grey mb-4 is-size-5">
      <sup>1</sup>H NMR data
    </h3>
    <div class="columns mb-5">
      <div id="nmr-form-proton" class="column is-8-tablet is-full-mobile">
        <div class="columns is-mobile mb-0">
          <label for="hNmr0" class="label column pb-0 mb-2">
            <span class="is-sr-only">1H</span> Chemical shift <span class="is-sr-only"> value row 1</span>
          </label>
        </div>
        <div class="columns is-mobile mb-0" v-for="(hNmr, index) in protonNmr" :key="index">
          <div class="column is-8-desktop is-7-widescreen">
              <label v-if="index > 0" class="is-sr-only" :for="`hNmr${index}`">
                1H Chemical shift value row {{ index + 1 }}
              </label>
            <div class="field has-addons mb-0">
              <TextInput
                class="control"
                rules="number"
                :id="`hNmr${index}`"
                v-model.trim="hNmr.value"
                @input="updateSearch"/>
              <div class="control">
                <label class="button is-static" :for="`hNmrVariance${index}`">
                  <span class="is-sr-only">1H Chemical shift</span> ± <span class="is-sr-only">variance row {{ index + 1 }}</span>
                </label>
              </div>
              <TextInput
                class="control"
                rules="number|non_negative"
                :id="`hNmrVariance${index}`"
                v-model.trim="hNmr.variance"
                v-on="hNmr.value.length ? { input: updateSearch } : {}"/>
              <div class="control">
                <span class="button is-static">ppm</span>
              </div>
            </div>
          </div>
          <AddRemoveRowButtons
            class="column is-narrow"
            :rowIndex="index"
            parameterId="protonNmr"
            addLabel="Add more 1H NMR chemical shifts"
            :removeLabel="removeNmrLabel(hNmr.value, hNmr.variance, null, index)"
            @updateSearch="updateSearch"
          />
        </div>
      </div>
    </div>

  </fieldset>
</template>

<script>
import { mapMultiRowFields } from 'vuex-map-fields';
import SearchPanelMixin from '@/mixins/search-panel-mixin';
export default {
  name: 'TabNMR',
  mixins: [SearchPanelMixin],
  computed: {
    ...mapMultiRowFields('search', {
      carbonNmr: 'SearchParams.carbonNmr',
      protonNmr: 'SearchParams.protonNmr',
    }),
  },
  methods: {
    removeNmrLabel(value, variance, protons, index) {
      if (value) {
        return `Remove${value ? ` ${value} ppm` : ''}${variance ? ` ± ${variance}` : ''}${protons ? `, Protons-${protons}` : ''}`;
      } else {
        return `Remove row ${index + 1}`;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
label[for^=cNmrVariance],
label[for^=hNmrVariance] {
  pointer-events: auto;
}
</style>