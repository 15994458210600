<template>
  <fieldset id="fieldset-bibliographic" class="px-5 py-5">

    <!-- Required by https://www.w3.org/TR/WCAG20-TECHS/H71.html -->
    <legend class="is-sr-only">{{ legend }}</legend>
    
    <!-- ARTICLE TITLE -->
    <TextInput
      horizontal
      id="articleTitle"
      label="Article title"
      v-model="articleTitle"
      @input="updateSearch"/>
    
    <!-- AUTHORS -->
    <div id="bibliographic-form-authors" class="columns mb-0 input-text-horizontal">
      <div class="column is-3-tablet is-2-desktop has-text-right-tablet">
        <span class="label pt-2">Authors</span>
      </div>
      <div class="column is-9-tablet is-6-desktop">
        <div class="columns is-mobile mb-0" v-for="(author, index) in authors" :key="index">
          <label class="is-sr-only" :for="`familyName${index}`">
            Author {{ index + 1 }} family name
          </label>
          <div class="column field has-addons mb-0">
            <TextInput
              class="control is-expanded"
              :id="`familyName${index}`"
              placeholder="family name"
              v-model="author.familyName"
              @input="updateSearch"
              @focus="showAuthorsHelp = true"
              @blur="showAuthorsHelp = false"/>
            <label class="is-sr-only" :for="`firstName${index}`">
              Author {{ index + 1 }} first name
            </label>
            <TextInput
              class="control"
              :id="`firstName${index}`"
              placeholder="first name"
              v-model="author.firstName"
              @input="updateSearch"
              @focus="showAuthorsHelp = true"
              @blur="showAuthorsHelp = false"/>
          </div>
          <AddRemoveRowButtons
            class="column is-narrow pl-1 mr-2"
            :rowIndex="index"
            parameterId="author"
            addLabel="Add another author"
            :removeLabel="removeAuthorLabel(author.familyName, author.firstName, index)"
            @updateSearch="updateSearch"
          />
        </div>
      </div>
      <div class="column is-hidden-touch is-4-desktop is-relative">
        <HelpBubble
          class="is-absolute"
          style="top: 0.75rem;"
          :class="{ 'is-hidden': !showAuthorsHelp }"
          helpTitle="Authors"
          helpText="To add additional authors, press the + button."/>
      </div>
    </div>
    
    <!-- JOURNALS -->
    <div class="columns is-vcentered mb-1 input-text-horizontal">
      <div class="column is-3-tablet is-2-desktop has-text-right-tablet">
        <label class="label" for="journal">Journal</label>
      </div>
      <div class="column is-9-tablet is-6-desktop">
        <div class="control">
          <v-select v-model="journal" inputId="journal" :options="journals" :filterable="false" @search="debouncedGetJournalTitles" @input="updateSearch"></v-select>
        </div>
      </div>
    </div>

    <!-- YEAR, VOLUME, PAGE -->
    <div class="columns mb-5">
      <div class="column is-9-tablet is-6-desktop is-offset-3-tablet is-offset-2-desktop">
        <div class="columns is-mobile">
          <div class="column is-one-third">
            <TextInput id="year" label="Year" :maxlength="4" v-model.trim="year" @input="updateSearch"/>
          </div>
          <div class="column is-one-third">
            <TextInput id="volume" label="Volume" v-model.trim="volume" @input="updateSearch"/>
          </div>
          <div class="column is-one-third">
            <TextInput id="page" label="Page" v-model.trim="page" @input="updateSearch"/>
          </div>
        </div>
      </div>
    </div>

    <!-- DOI -->
    <TextInput
      horizontal
      id="doi"
      label="DOI"
      rules="doi"
      placeholder="e.g. 10.1039/d0cc03055d"
      v-model.trim="doi"
      @input="updateSearch"/>

    <!-- CATEGORY -->
    <div class="columns is-vcentered input-text-horizontal">
      <div class="column is-3-tablet is-2-desktop has-text-right-tablet">
        <label class="label" for="category">Categories</label>
      </div>
      <div class="column is-9-tablet is-6-desktop">
        <div class="control">
          <v-select multiple v-model="category" inputId="category" :options="categories" @input="updateSearch">
            <template #option="{ category, subcategory }">
              <b v-if="!subcategory" class="is-block py-1">{{ category }}</b>
              <div v-else class="columns is-gapless is-multiline is-vcentered">
                <div class="column ml-3">{{ subcategory }}</div>
                <div class="column ml-3 is-narrow vs__dropdown-option-note">{{ category }}</div>
              </div>
            </template>
          </v-select>
        </div>
      </div>
    </div>
    
    <!-- ARTICLE ID -->
    <TextInput
      horizontal
      id="articleId"
      rules="numeric"
      label="MarinLit ID"
      prefix="A"
      v-model.trim="articleId"
      @input="updateSearch"/>

  </fieldset>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields, mapMultiRowFields } from 'vuex-map-fields';
import { dataService } from '@/api';
import { debounce } from 'lodash';
import SearchPanelMixin from '@/mixins/search-panel-mixin';
export default {
  name: 'TabBibliographic',
  mixins: [SearchPanelMixin],
  data() {
    return {
      journals: [],
      categories: [],
      showAuthorsHelp: false,
    }
  },
  methods: {
    ...mapActions('search', [
      'addEmptyRowSearchParameterAction',
      'removeMultiRowSearchParameterAction',
    ]),
    debouncedGetJournalTitles: debounce(async function(search, loading) {
      loading(true);
      const result = await dataService.getJournalTitles(encodeURIComponent(search));
      loading(false);
      this.journals = result.Data;
    }, 400),
    removeAuthorLabel(familyName, firstName, index) {
      if (familyName) {
        return `Remove author${firstName ? ` ${firstName}` : ''}${familyName ? ` ${familyName}` : ''}`;
      } else {
        return `Remove author row ${index + 1}`;
      }
    },
  },
  computed: {
    ...mapFields('search', {
      articleTitle: 'SearchParams.articleTitle.value',
      journal: 'SearchParams.journal.value',
      year: 'SearchParams.year.value',
      volume: 'SearchParams.volume.value',
      page: 'SearchParams.page.value',
      doi: 'SearchParams.doi.value',
      category: 'SearchParams.category',
      articleId: 'SearchParams.articleId.value',
    }),
    ...mapMultiRowFields('search', {
      authors: 'SearchParams.author',
    }),
  },
  async mounted() {
    // Initialise v-select data
    const categoriesResult = await dataService.getArticleCategories();
    this.categories = categoriesResult.Data ? categoriesResult.Data : [];
    const journalTitlesResult = await dataService.getJournalTitles('');
    this.journals = journalTitlesResult.Data ? journalTitlesResult.Data : [];
  },
};
</script>
