<template>
  <div class="py-6">
    <div class="container content">
      <div class="columns is-centered">
        <div class="column is-offset-1-mobile is-10-mobile is-half-tablet is-two-fifths-widescreen is-one-third-fullhd">
          <div class="block has-text-centered">
            <h1 class="title is-3">Sign in</h1>
            <p class="subtitle has-text-black">
              using your subscriber credentials
            </p>
          </div>
          <ValidationObserver tag="div" class="box" ref="loginForm" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(signIn)">
              <div v-if="error" class="message is-danger" role="alert">
                <p class="message-body">{{ error }}</p>
              </div>
              <fieldset id="fieldset-login" :disabled="busy">
                <TextInput
                  id="username"
                  class="mb-4"
                  rules="required"
                  label="User Name"
                  inputClass="is-medium"
                  :clearButton="false"
                  v-model.trim="username" />
                <TextInput
                  id="password"
                  class="mb-4"
                  rules="required"
                  label="Password"
                  inputType="password"
                  inputClass="is-medium"
                  :clearButton="false"
                  v-model="password" />
                <button
                  type="submit"
                  id="btn-submit-log-in"
                  :class="['button is-medium is-link is-fullwidth', { 'is-loading': busy }]">
                  Sign in
                </button>
              </fieldset>
            </form>
          </ValidationObserver>
          <div class="block has-text-centered">
            <p>
              Your username and password will have been provided to you by your librarian/information
              professional to allow you to access subscriber content on MarinLit.
            </p>
            <p>
              <b>Need to arrange access?</b><br />
              <a href="mailto:marinlit@rsc.org">marinlit@rsc.org</a> or
              <a href="mailto:sales@rsc.org">sales@rsc.org</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import { dataService } from '@/api';
import TextInput from '@/components/forms/input-text';
export default {
  name: 'Login',
  metaInfo: {
    title: 'Subscriber login'
  },
  data() {
    return {
      username: '',
      password: '',
      busy: false,
      error: '',
    }
  },
  methods: {
    ...mapActions('auth', [
      'isLoggedInAs',
    ]),
    ...mapActions('search', [
      'updateAllSearchResultsAction',
    ]),
    async signIn() {
      this.busy = true;
      const result = await dataService.authenticate(this.username, this.password);
      if (result.Data && result.Data.isLoggedIn) {
        this.isLoggedInAs(result.Data);
        this.$router.push(this.$route.query.redirect || '/');
        // Refresh search results in case user is logging back in after JWT expiry
        await this.updateAllSearchResultsAction();
      }
      else if (result.Data && result.Data.error) {
        this.busy = false;
        this.error = result.Data.error;
      }
      else if (result.ServiceStatus.HasError && result.ServiceStatus.Error) {
        this.busy = false;
        this.error = result.ServiceStatus.Error;
      }
    },
  },
  components: {
    TextInput,
    ValidationObserver,
  },
};
</script>
