<template>
  <div id="page-title" class="px-4 py-5 is-translucent">
    <div class="columns is-vcentered">
      <div class="column is-narrow">
        <a href @click.prevent="$router.go(-1)" aria-label="Back to previous page" class="button is-info has-text-weight-bold" id="btn-go-back">
          <span class="level-item mr-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 192 192" aria-hidden="true">
              <path d="M119.8 169l-73-73 73-73 16.9 17-56 56 56 56z"/>
            </svg>
          </span>
          Back
        </a>
      </div>
      <div class="column">
        <h1 class="title has-text-white" v-html="title"></h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
}
</script>
