<template>
  <div
    v-if="ipAuthenticationError"
    role="alert"
    id="header-auth-error"
    class="px-4 py-2 has-background-danger-dark has-text-white">
    <b>Authentication error</b><br> {{ ipAuthenticationError }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'AuthError',
  computed: {
    ...mapGetters('auth', [
      'ipAuthenticationError',
    ]),
  },
}
</script>
