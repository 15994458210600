<template>
  <div
    :class="{ 'modal-background search-panel-overlay': IsSearchPanelVisible }"
    @click="setSearchPanelVisibilityAction(false)"
  >
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: 'SearchPanelOverlay',
  methods: {
    ...mapActions('search', [
      'setSearchPanelVisibilityAction',
    ]),
  },
  computed: {
    ...mapState('search', {
      IsSearchPanelVisible: state => state.IsSearchPanelVisible,
    }),
  },
}
</script>
