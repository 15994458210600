<template>
  <div class="content" id="compound-record-chshifts" aria-labelledby="compound-tab-chshifts">
    <div class="table-container">
      <table class="table is-narrow is-bordered nmr-table">
        <caption class="title is-size-5 has-text-left">
          <sup>13</sup>C and <sup>1</sup>H chemical shifts for <span v-html="compound.title"></span>
        </caption>
        <thead>
          <tr>
            <th>Atom number</th>
            <th>Attached protons</th>
            <th><sup>13</sup>C chemical shifts (ppm)</th>
            <th><sup>1</sup>H chemical shifts (ppm)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="s in chemicalShifts" :key="`locant${s.locant}`">
            <td>{{ s.locant }}</td>
            <td>{{ s.protons }}</td>
            <td>{{ s.carbonValue }}</td>
            <td v-html="s.protonValues"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabShifts',
  props: {
    compound: {
      type: Object,
      required: true,
    },
  },
  computed: {
    chemicalShifts() {
      return this.compound.nmrData.map(c => ({
        locant: c.locant,
        protons: c.carbonValue ? c.protons : '',
        carbonValue: c.carbonValue ? `${c.carbonValue.value.toFixed(1)} ± ${c.carbonValue.variance.toFixed(1)}` : '',
        protonValues: c.protonValues.map(p => `${p.value.toFixed(2)} ± ${p.variance.toFixed(2)}`).join('<br>'),
      }));
    },
  },
}
</script>
