<template>
  <ValidationProvider
    tag="div"
    :class="{ 'mb-3': horizontal }"
    :rules="rules"
    v-slot="{ errors }">

    <div :class="{ 'columns is-vcentered input-text-horizontal mb-0': horizontal }">
      
      <!-- Label (inline) -->
      <div
        v-if="horizontal"
        class="column is-3-tablet is-2-desktop has-text-right-tablet">
        <label class="label" :class="{ 'has-text-weight-normal': unbold }" :for="id" v-html="label"></label>
      </div>

      <!-- Label (above) -->
      <label v-else-if="label" class="label" :class="{ 'has-text-weight-normal': unbold }" :for="id" v-html="label"></label>

      <!-- Field -->
      <div
        class="field"
        :class="{ 'has-addons': prefix || suffix,
                  'column is-9-tablet is-6-desktop mb-0': horizontal }">
        
        <!-- Prefix (optional) -->
        <div v-if="prefix" class="control">
          <span class="button is-static">{{ prefix }}</span>
        </div>

        <!-- Field wrapper -->
        <div class="control" :class="{ 'is-loading': isLoading, 'is-expanded': prefix, 'has-icons-right': clearButton }">
          
          <input
            class="input"
            autocomplete="off"
            :type="inputType"
            :class="[ inputClass, { 'is-danger': !!errors.length }]"
            :id="id"
            :value="value"
            :maxlength="maxlength"
            :placeholder="placeholder"
            :aria-invalid="!!errors.length"
            :aria-errormessage="`${id}Err`"
            @input="updateValue($event.target.value)"
            @keyup.enter="updateValue($event.target.value)"
            @focus="focus"
            @blur="blur"
          />

          <!-- Clear field button -->
          <span v-if="clearButton" class="icon is-right">
            <button type="button" @click="updateValue('')" tabindex="-1" v-show="isLoaded" :id="`btn-delete-${id}`" class="delete is-medium" title="Remove value">
              Remove value
            </button>
          </span>

          <!-- Validation message -->
          <p v-if="!!errors.length && !horizontal" role="alert" class="help is-danger" :id="`${id}Err`">
            {{ errors[0] }}
          </p>

        </div>
        
        <!-- Suffix (optional) -->
        <div v-if="suffix" class="control">
          <span class="button is-static">{{ suffix }}</span>
        </div>

      </div>

      <!-- Optional help bubble (horizontal only) -->
      <div v-if="horizontal && hasHelpBubble" class="column is-hidden-touch is-4-desktop is-relative">
        <HelpBubble
          class="is-absolute"
          :helpTitle="helpTitle"
          :helpText="helpText"
          :class="{ 'is-hidden': !isFocused }"
        />
      </div>
    
    </div>

    <!-- Validation message (horizontal) -->
    <div v-if="!!errors.length && horizontal" class="columns mb-3">
      <div class="column is-offset-3-tablet is-offset-2-desktop py-0">
        <p class="help is-danger" role="alert" :id="`${id}Err`">
          {{ errors[0] }}
        </p>
      </div>
    </div>

  </ValidationProvider>

</template>

<script>
import { mapState } from 'vuex';
import { ValidationProvider, extend } from 'vee-validate';
import { integer, numeric, required } from 'vee-validate/dist/rules';
import HelpBubble from '@/components/forms/help-bubble';

// For required text fields
extend('required', {
  ...required,
  message: 'This field is required'
});

// For all numeric input fields (positive, negative, decimals, integers...)
extend('number', {
  validate: value => !isNaN(value),
  message: 'Must be a number'
});

// Used for exact mass, UV maxima, variance and functional groups
extend('non_negative', {
  validate: value => value >= 0,
  message: 'Must be non-negative'
});

// Used for functional groups
extend('integer', {
  ...integer,
  message: 'Must be an integer'
});

// Used for DOI
extend('doi', {
  validate: value => value.startsWith('10.'),
  message: 'Must start with 10.'
});

// Used for MarinLit ID (only accepts 1234567890)
extend('numeric', {
  ...numeric,
  message: 'Must be a number'
});

export default {
  name: 'TextInput',
  data() {
    return {
      isFocused: false,
    }
  },
  props: {
    id:          { type: String, required: true, validator: value => { return value.length } },
    label:       { type: String, default: '' },
    unbold:      { type: Boolean, default: false, },
    horizontal:  { type: Boolean, default: false, },
    prefix:      { type: String, default: '' },
    suffix:      { type: String, default: '' },
    inputType:   { type: String, default: 'text' }, 
    inputClass:  { type: String, default: '' },
    placeholder: { type: String, default: '' },
    rules:       { type: String, default: '' },
    maxlength:   { type: Number, default: 255 },
    value:       { type: String, default: '' },
    clearButton: { type: Boolean, default: true },
    helpTitle:   { type: String, default: '' },
    helpText:    { type: String, default: '' },
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);
    },
    focus() {
      this.isFocused = true;
      this.$emit('focus');
    },
    blur() {
      this.isFocused = false;
      this.$emit('blur');
    },
  },
  computed: {
    isLoading() {
      return this.clearButton && (this.IsCompoundsLoading || this.IsArticlesLoading);
    },
    isLoaded() {
      return !!this.value && !this.isLoading;
    },
    hasHelpBubble() {
      return !!this.helpTitle || !!this.helpText;
    },
    ...mapState('compounds', {
      IsCompoundsLoading: state => state.ServiceStatus.AwaitingResponse,
    }),
    ...mapState('articles', {
      IsArticlesLoading: state => state.ServiceStatus.AwaitingResponse,
    }),
  },
  components: {
    HelpBubble,
    ValidationProvider,
  },
};
</script>
