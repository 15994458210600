<template>
  <div class="column is-narrow">
    <div class="select level">
      <label for="sortMode" class="label has-text-white mx-3 my-2 sort-mode-label">
        Sort <span class="is-sr-only">{{ mode }}</span> by
      </label>
      <select id="sortMode" v-model="sortMode" @change="updateSortMode">
        <option v-for="mode in sortModes[this.mode]" :value="mode.id" :key="mode.id">
          {{ mode.label }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: 'SortByControl',
  props: {
    mode: {
      type: String,
      required: true,
      validator: function(value) {
        return ['compounds', 'articles'].indexOf(value) !== -1;
      }
    }
  },
  data: function() {
    return {
      sortModes: {
        compounds: [
          { id: 'relevance', label: 'Relevance' },
          { id: 'pubdate', label: 'Latest' },
          { id: 'title', label: 'A-Z' }
        ],
        articles: [
          { id: 'relevance', label: 'Relevance' },
          { id: 'pubdate', label: 'Latest' }
        ]
      }
    };
  },
  computed: {
    sortMode: {
      get() {
        return this.$store.state[this.mode].SortMode;
      },
      set(value) {
        const sortMode = this.sortModes[this.mode].find(x => x.id === value).label;
        this.$gtm.trackEvent({
          event: 'option-selected',
          category: 'Sort Mode',
          value: sortMode
        });
        return this.mode === 'compounds'
          ? this.updateCompoundSortModeAction(value)
          : this.updateArticleSortModeAction(value);
      }
    }
  },
  methods: {
    ...mapActions('compounds', ['updateCompoundSortModeAction']),
    ...mapActions('articles', ['updateArticleSortModeAction']),
    updateSortMode() {
      this.$emit('toggleSort', this.mode);
    }
  }
};
</script>
