<template>
  <div class="column is-narrow">
    <div class="buttons has-addons" id="compound-view-modes">
      <button
        v-for="viewMode in compoundViewModes"
        type="button"
        :key="viewMode.id"
        :id="`btn-view-mode-${viewMode.id}`"
        :title="`${viewMode.label} view`"
        class="button is-primary is-inverted"
        :class="compoundViewMode === viewMode.id ? 'is-selected' : 'is-outlined'"
        @click="toggleCompoundViewMode(viewMode.id)"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24" height="24" v-html="viewMode.svgPath" aria-hidden="true"></svg>
        <span class="ml-1 view-mode-label">{{ viewMode.label }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: 'CompoundViewModes',
  data: function() {
    return {
      compoundViewModes: [
        {
          id: 'list',
          label: 'List',
          svgPath: '<path d="M0 0h24v24H0z" fill="none"/><path d="M4 14h4v-4H4v4zm0 5h4v-4H4v4zM4 9h4V5H4v4zm5 5h12v-4H9v4zm0 5h12v-4H9v4zM9 5v4h12V5H9z"/>',
        },
        {
          id: 'grid',
          label: 'Grid',
          svgPath: '<path d="M0 0h24v24H0z" fill="none"/><path d="M4 11h5V5H4v6zm0 7h5v-6H4v6zm6 0h5v-6h-5v6zm6 0h5v-6h-5v6zm-6-7h5V5h-5v6zm6-6v6h5V5h-5z"/>',
        },
        {
          id: 'map',
          label: 'Map',
          svgPath: '<path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>',
        },
      ]
    };
  },
  methods: {
    ...mapActions('compounds', [
      'updateCompoundViewModeAction'
    ]),
    toggleCompoundViewMode(mode) {
      this.$gtm.trackEvent({
        event: 'option-selected',
        category: 'View Mode',
        value: mode
      });
      this.updateCompoundViewModeAction(mode);
    },
  },
  computed: {
    ...mapState('compounds', {
      compoundViewMode: state => state.ViewMode
    }),
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_bulma-modifiers';
button.is-outlined:hover,
button.is-outlined:focus,
button.is-selected {
  span {
    color: $cyan;
  }
  svg {
    fill: $cyan;
  }
}
</style>