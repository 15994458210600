import { dataService } from '@/api';
import {
  INITIALISE_TOTAL_NUMBER_OF_ARTICLES,
  UPDATE_ARTICLE_SORT_MODE,
  UPDATE_ARTICLE_SEARCH_RESULTS,
  UPDATE_ARTICLE_SEARCH_RESULTS_PAGE_NUMBER,
  SET_ARTICLE_SERVICE_WAITING,
  UPDATE_ARTICLE_REQUEST_TIMESTAMP,
} from '../mutation-types';

export default {

  namespaced: true,

  // STATE
  state: {
    SearchResults: [],
    SearchResultsTotalCount: 0,
    SearchResultsPageNumber: 0,
    SortMode: 'relevance',
    ServiceStatus: {
      AwaitingResponse: false,
      HasError: false,
      Error: null,
    },
    AllArticlesCount: 0,
    RequestTimestamps: {
      Articles: 0,
    },
  },

  // MUTATIONS
  mutations: {
    [INITIALISE_TOTAL_NUMBER_OF_ARTICLES](state, count) {
      state.AllArticlesCount = count;
      state.SearchResultsTotalCount = count;
    },
    [UPDATE_ARTICLE_SORT_MODE](state, sortMode) {
      state.SortMode = sortMode;
    },
    [UPDATE_ARTICLE_SEARCH_RESULTS](state, payload) {
      if (payload.searchResults.Data) {
        if (payload.clearExistingResults) {
          state.SearchResults = payload.searchResults.Data.articles;
        }
        else {
          state.SearchResults.push(...payload.searchResults.Data.articles);
        }
        state.SearchResultsTotalCount = payload.searchResults.Data.totalNumberOfArticles;
      }
      else {
        state.SearchResults = [];
        state.SearchResultsTotalCount = 0;
      }
      state.ServiceStatus = payload.searchResults.ServiceStatus;
    },
    [UPDATE_ARTICLE_SEARCH_RESULTS_PAGE_NUMBER](state, pageNumber) {
      state.SearchResultsPageNumber = pageNumber;
    },
    [SET_ARTICLE_SERVICE_WAITING](state) {
      state.ServiceStatus.AwaitingResponse = true;
      state.ServiceStatus.HasError = false;
      state.ServiceStatus.Error = '';
    },
    [UPDATE_ARTICLE_REQUEST_TIMESTAMP](state, id) {
      state.RequestTimestamps[id] = (new Date()).getTime();
    },
  },

  // ACTIONS
  actions: {
    initialiseTotalNumberOfArticles({ commit, dispatch }, value) {
      commit(INITIALISE_TOTAL_NUMBER_OF_ARTICLES, value);
      dispatch('search/updateSearchResultCountsAction', { articles: value }, { root: true });
    },
    updateArticleSortModeAction({ commit }, value) {
      commit(UPDATE_ARTICLE_SORT_MODE, value);
    },
    async updateArticleSearchResultsAction({ commit, state, rootGetters }, clearExistingResults) {
      commit(SET_ARTICLE_SERVICE_WAITING);
      commit(UPDATE_ARTICLE_REQUEST_TIMESTAMP, 'Articles');
      const timestamp = state.RequestTimestamps.Articles;
      commit(UPDATE_ARTICLE_SEARCH_RESULTS_PAGE_NUMBER,
        clearExistingResults ? 0 : state.SearchResultsPageNumber + 1
      );
      const searchResults = await dataService.getArticles(
        rootGetters['search/searchUrlQueryString'],
        state.SortMode,
        state.SearchResultsPageNumber,
      );
      // Discard stale results
      if (state.RequestTimestamps.Articles === timestamp) {
        commit(UPDATE_ARTICLE_SEARCH_RESULTS, { searchResults, clearExistingResults });
      }
    },
  },

  // GETTERS
  getters: {
    
  },

}
