<template>
  <div>
    <div v-if="article" class="v-stretch">
      <PageTitle :title="article.articleTitle" />
      <div class="has-background-white px-5 py-6 v-expand" id="article-record-body">
        <div class="content mb-5" id="article-record-content">
          <section v-if="article.authors" class="mb-5" id="article-record-authors">
            <h2 class="data-label has-text-weight-bold is-size-5">Authors</h2>
            <p>{{ article.authors }}</p>
          </section>
          <section v-if="article.journalTitle || article.digitalObjectIdentifier" class="mb-5" id="article-record-citation">
            <h2 class="data-label has-text-weight-bold is-size-5 is-inline-block mr-2">Citation</h2>
            <a role="button" class="text-button mx-1" :href="this.articleCitationRisUrl(article.articleId)" id="btn-download-ris" download>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" aria-hidden="true">
                <path d="M0 0h24v24H0z" fill="none"/>
                <path d="M 19,20 V 18 H 5 v 2 z M 19,10 H 15 V 4 H 9 v 6 H 5 l 7,7 z"/>
              </svg>
              <span class="is-sr-only">Download</span> RIS
            </a>
            <a role="button" class="text-button mx-1" :href="this.articleCitationBibUrl(article.articleId)" id="btn-download-bibtex" download>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" aria-hidden="true">
                <path d="M0 0h24v24H0z" fill="none"/>
                <path d="M 19,20 V 18 H 5 v 2 z M 19,10 H 15 V 4 H 9 v 6 H 5 l 7,7 z"/>
              </svg>
              <span class="is-sr-only">Download</span> BibTeX
            </a>
            <p class="mb-0">
              <ArticleCitation :article="article" />
            </p>
            <DoiLink v-if="article.digitalObjectIdentifier" :doi="article.digitalObjectIdentifier" />
          </section>
          <section v-if="article.trivialNames" class="mb-5" id="article-record-trivial">
            <h2 class="data-label has-text-weight-bold is-size-5">Trivial names</h2>
            <p>{{ article.trivialNames }}</p>
          </section>
          <section v-if="article.note" class="mb-5" id="article-record-note">
            <h2 class="data-label has-text-weight-bold is-size-5">Note</h2>
            <p v-html="article.note"></p>
          </section>
          <!-- DS-3343 'keywords' in the API response (a comma-separated list of categories) has been renamed -->
          <!-- to 'categoriesDisplay'. This falls back to 'keywords' until the API and projections catch up.  -->
          <section v-if="article.categoriesDisplay || article.keywords" class="mb-5" id="article-record-categories">
            <h2 class="data-label has-text-weight-bold is-size-5">Categories</h2>
            <p>{{ article.categoriesDisplay ? article.categoriesDisplay : article.keywords }}</p>
          </section>
        </div>
        <div v-if="this.visibleTabs.length" id="article-record-tabs">
          <div class="tabs is-boxed is-medium">
            <ul role="tablist">
              <li v-for="tab in visibleTabs" :key="tab.id" :class="{ 'is-active': currentTab === tab.id }">
                <a href @click.prevent="switchTab(tab.id)"
                  role="tab"
                  :aria-selected="currentTab === tab.id"
                  :aria-controls="`article-record-${tab.id.toLowerCase()}`"
                  :id="`article-tab-${tab.id.toLowerCase()}`">
                  {{ tab.title }}
                </a>
              </li>
            </ul>
          </div>
          <div ref="tabPanel" role="tabpanel" tabindex="-1">
            <component v-bind:is="currentTabComponent" :article="article" />
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="error" class="v-stretch">
      <PageTitle title="Error" />
      <RecordError type="article" :error="error" class="v-expand" />
    </div>
  </div>
</template>

<script>
import { dataService } from '@/api';
import urlHelpers from '@/mixins/url-helpers';
import { TabCompounds, TabTaxonomy, TabLocation, TabArticles } from '@/components/article-record';
import PageTitle from '@/components/shared/page-title';
import ArticleCitation from '@/components/shared/article-citation';
import CompoundImage from '@/components/shared/compound-image';
import DoiLink from '@/components/shared/doi-link';
import RecordError from '@/components/shared/record-error';

export default {
  name: 'Article',
  metaInfo () {
    return {
      title: this.article ? this.article.plainTextArticleTitle
        : this.error ? this.error
        : 'Loading...'
    }
  },
  mixins: [urlHelpers],
  data() {
    return {
      article: null,
      error: null,
      currentTab: '',
      tabs: [
        {
          id: 'Compounds',
          title: 'Compound data',
          visible: false,
          visibilityKey: 'substances', // Array
        },
        {
          id: 'Taxonomy',
          title: 'Taxonomy',
          visible: false,
          visibilityKey: 'taxonomyList', // Array
        },
        {
          id: 'Location',
          title: 'Location',
          visible: false,
          visibilityKey: 'locations', // Array
         },
        {
          id: 'Articles',
          title: 'Related articles',
          visible: false,
          visibilityKey: 'relatedArticles', // Array
        },
      ],
    };
  },
  async created() {
    await this.fetchArticle();
  },
  watch: {
    // Run the fetch again if the route changes, i.e. navigating between related articles
    '$route': 'fetchArticle'
  },
  methods: {
    async fetchArticle() {
      const response = await dataService.getSingleArticle(this.$route.params.id);
      if (typeof response !== 'object') {
        this.error = 404;
      }
      else {
        this.article = response;
        this.setTabVisibility();
        this.activateFirstVisibleTab();
      }
    },
    setTabVisibility() {
      for (var i = 0; i < this.tabs.length; i++) {
        const key = this.article[this.tabs[i].visibilityKey];
        // Check for compounds, taxonomy, location and related articles
        this.tabs[i].visible = key && key.length;
      }
    },
    activateFirstVisibleTab() {
      if (this.visibleTabs.length) {
        this.currentTab = this.tabs.find(tab => tab.visible).id;
      }
    },
    switchTab(id) {
      this.currentTab = id;
      // Move focus into tab panel whenever a tab is selected, so that
      // keyboard users don't have to tab through other tabs to reach
      // the content. A short wait ensures that the correct tab panel
      // is announced rather than the previously-visible tab panel.
      setTimeout(() => this.$refs.tabPanel.focus(), 100);
    },
  },
  computed: {
    currentTabComponent: function() {
      return "Tab" + this.currentTab;
    },
    visibleTabs: function() {
      return this.tabs.filter(tab => tab.visible);
    },
  },
  components: {
    PageTitle,
    ArticleCitation,
    CompoundImage,
    DoiLink,
    TabCompounds,
    TabTaxonomy,
    TabLocation,
    TabArticles,
    RecordError,
  },
};
</script>
