<template>
  <ul aria-labelledby="heading-article-search-results">
    <li v-for="article in articles" :key="article.articleId">
      <article class="capsule mb-4">
        <router-link
          class="is-block-link"
          :to="{ name: 'Article', params: { id: article.articleId }}">
          <h2 class="is-size-4 has-text-link is-block-link-underline px-3 py-3" v-html="article.articleTitle"></h2>
          <div class="pb-3" v-if="article.authors || article.journalTitle">
            <p class="has-text-grey-darker px-3" v-if="article.authors">
              {{ article.authors }}
            </p>
            <p class="has-text-grey-darker px-3" v-if="article.journalTitle">
              <ArticleCitation :article="article" />
            </p>
          </div>
        </router-link>
        <div class="columns is-marginless is-gapless">
          <div class="column">
            <DoiLink class="is-block is-size-5 px-3 py-3" v-if="article.digitalObjectIdentifier" :doi="article.digitalObjectIdentifier" />
          </div>
          <div class="column is-narrow">
            <router-link
              tabindex="-1"
              aria-hidden="true"
              class="is-block is-hidden-mobile is-size-5 px-3 py-3 hover-underline"
              :to="{ name: 'Article', params: { id: article.articleId }}"
            >Details</router-link>
          </div>
        </div>
      </article>
    </li>
  </ul>
</template>

<script>
import ArticleCitation from '@/components/shared/article-citation';
import DoiLink from '@/components/shared/doi-link';
export default {
  name: 'ArticleList',
  props: {
    articles: {
      type: Array,
      default: () => []
    }
  },
  components: {
    ArticleCitation,
    DoiLink
  }
};
</script>
