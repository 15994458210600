<template>
  <div class="content has-text-centered px-4 py-6">
    <h1 class="title is-3">No {{ searchType }} found</h1>
    <p class="is-size-5">
      Try widening or removing some search criteria.
    </p>
  </div>
</template>

<script>
  export default {
    name: 'NoResultsMessage',
    props: {
      searchType: {
        type: String,
        default: 'results',
      },
    },
  }
</script>
