<template>
  <div>
    <div class="results-warning my-2 px-5 py-4" v-if="compoundLocations.isCompoundResultsLimitReached">
      <b class="is-block is-size-5 mb-2">
        Zoom in to see all compounds discovered at a location
      </b>
      <span class="is-block mb-1">
        So the map loads quickly, we are displaying the first {{ compoundLocations.compoundResultsLimit }}
        results of your {{ compoundSearchResultsTotalCount }} compounds
      </span>
    </div>
    <div class="google-map" ref="googleMap"></div>
  </div>
</template>

<script>
import GoogleMapsMixin from '@/mixins/google-maps-mixin';
import MarkerClusterer from 'node-js-marker-clusterer';
import { mapActions, mapState } from 'vuex';
import debounce from 'lodash.debounce';

export default {
  name: 'CompoundMap',
  mixins: [GoogleMapsMixin],
  props: {
    compoundLocations: {
      results: {
        type: Array,
        default: () => []
      },
      isCompoundResultsLimitReached: false,
      compoundResultsLimit: 0
    },
  },
  data() {
    return {
      mapMarkers: [],
      markerCluster: null,
      mapConfig: {
        center: { lat: 0, lng: 0 },
        zoom: 2,
        mapTypeId: 'roadmap',
        streetViewControl: false,
        styles: [{
            featureType: 'poi',
            elementType: 'labels',
            stylers: [{
                visibility: 'off',
            }],
        }],
      },
    };
  },
  methods: {
    ...mapActions('compounds', [
      'updateMapVisibleBoundsAction'
    ]),

    getMapMarkerContent(data) {
      let infoContent = '';
      for (const substanceData of data.substanceList) {
        infoContent 
          += `<p>
                <a data-compound-id="${substanceData.compoundId}" href="${this.$router.resolve({ name: 'Compound', params: { id: substanceData.compoundId }}).href}">
                  ${substanceData.title}
                </a>
              </p>`;
      }
      return `<div>
                <div style='max-height: 150px'>
                  <p>Substance${data.substanceList.length > 1 ? 's' : ''} found at this location:</p>
                  ${infoContent}
                </div>
              </div>`;
    },
    
    updateMap() {
      if (this.markerCluster) {
        this.markerCluster.clearMarkers();
      }
      this.mapMarkers = [];
      let infoWindow = new this.google.maps.InfoWindow();

      // Modify links on the fly to ensure that Vue Router handles navigation to
      // compound record pages from map marker windows
      (function(infoWindow, router) {
        window.google.maps.event.addListener(infoWindow, 'domready', function() {
          for (const a of document.querySelectorAll('a[data-compound-id]')) {
            let compoundId = a.getAttribute('data-compound-id');
            a.onclick = (e) => {
              e.preventDefault();
              router.push({
                name: 'Compound',
                params: { id: compoundId }
              });
            };
          }
        });
      })(infoWindow, this.$router);

      // Update results on map zoom/move
      (function(debouncedHandleMapVisibleBoundsChanged, map){
        window.google.maps.event.addListener(map, 'bounds_changed', function() {
          debouncedHandleMapVisibleBoundsChanged(map);
        });
      })(this.debouncedHandleMapVisibleBoundsChanged, this.map);

      for (const data of this.compoundLocations.results) {
        let myLatLng = new this.google.maps.LatLng(
          data.latitude,
          data.longitude
        );
        let marker = new this.google.maps.Marker({
          position: myLatLng,
          map: this.map,
          title: data.name
        });
        (function(marker, data, getMapMarkerContent) {
          window.google.maps.event.addListener(marker, 'click', function() {
            infoWindow.setContent(getMapMarkerContent(data));
            infoWindow.open(this.map, marker);
          });
        })(marker, data, this.getMapMarkerContent);

        // Add marker to global object for distribution and clustering
        this.mapMarkers.push(marker);
      }

      // Initialise MarkerClusterer library
      this.markerCluster = new MarkerClusterer(this.map, this.mapMarkers, {
        minimumClusterSize: 3,
        maxZoom: 15,
        imagePath: 'img/maps/m',
      });
    },
    debouncedHandleMapVisibleBoundsChanged: debounce(async function(map) {
      const bounds = map.getBounds();
      const mapViewInfo = {
        // Elasticsearch expects topLeft and bottomRight, but Google Maps API
        // only offers getNorthEast() and getSouthWest() so swap things around
        topLeft: {
          lat: bounds.getNorthEast().lat(),
          lon: bounds.getSouthWest().lng(),
        },
        bottomRight: {
          lat: bounds.getSouthWest().lat(),
          lon: bounds.getNorthEast().lng(),
        },
      };
      await this.updateMapVisibleBoundsAction(mapViewInfo);
    }, 800),
  },
  computed: {
    ...mapState('search', {
      compoundSearchResultsTotalCount: state => state.SearchResultCounts.compounds,
    }),
  },
  watch: {
    // The watch is needed here because the map markers are rendered without Vue data binding
    compoundLocations: function() {
      this.updateMap();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/_bulma-modifiers';
.google-map {
  height: 700px;
  width: 100%;
}

.results-warning {
  z-index: 1;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0);
  color: $grey;
  border-radius: 4px;
  border: 1px solid $grey;
  background-color: #ffffffdd;
  box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.6);
}
</style>
