import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';
import CompoundResults from '@/views/CompoundResults.vue';
import CompoundRecord from '@/views/CompoundRecord.vue';
import ArticleResults from '@/views/ArticleResults.vue';
import ArticleRecord from '@/views/ArticleRecord.vue';
import CookieCompliance from '@/views/CookieCompliance.vue';
import PageNotFound from '@/views/PageNotFound.vue';
import store from '@/store';
import { dataService } from '@/api';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { elementId: 'page-home' },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { elementId: 'page-login' },
    beforeEnter: (to, from, next) => {
      // Redirect to home page if already logged in
      if (store.getters['auth/isAuthenticated']) {
        next('/');
      }
      else {
        next();
      }
    }
  },
  {
    path: '/compounds',
    name: 'Compounds',
    component: CompoundResults,
    meta: { elementId: 'page-compound-results', requiresAuth: true, sortMode: 'compounds' },
  },
  {
    path: '/compounds/:id',
    name: 'Compound',
    component: CompoundRecord,
    meta: { elementId: 'page-compound-record', requiresAuth: true, hideToolbar: true },
  },
  {
    path: '/articles',
    name: 'Articles',
    component: ArticleResults,
    meta: { elementId: 'page-article-results', requiresAuth: true, sortMode: 'articles' },
  },
  {
    path: '/articles/:id',
    name: 'Article',
    component: ArticleRecord,
    meta: { elementId: 'page-article-record', requiresAuth: true, hideToolbar: true },
  },
  {
    path: '/marinlit',
    redirect: '/',
    children: [
      { path: 'contact', redirect: '/' },
      { path: 'introduction', redirect: '/' },
      { path: 'howtoaccess', redirect: '/' },
      { path: 'howtosearch', redirect: '/' },
      { path: 'subscriberlogin', redirect: '/login' },
      { path: 'search/bibliographic', redirect: '/' },
      { path: 'search/compound', redirect: '/' },
      { path: 'search/taxonomy', redirect: '/' },
      { path: 'search/location', redirect: '/' },
      { path: 'searchresult/articles', redirect: '/articles' },
      { path: 'searchresult/compounds', redirect: '/compounds' },
      // Redirect legacy compound and article page URLs
      {
        path: 'compound/:csid',
        beforeEnter: (to, from, next) => {
          dataService.translateLegacyId(to.params.csid).then(
            (response) => next(response.Data ? `/compounds/${response.Data.marinLitId}` : '/compounds')
          );
        },
      },
      {
        path: 'article/:cmid/:articleId?',
        beforeEnter: (to, from, next) => {
          dataService.translateLegacyId(to.params.cmid).then(
            (response) => next(response.Data ? `/articles/${response.Data.marinLitId}` : '/articles')
          );
        },
      },
    ]
  },
  {
    path: '/cookies',
    name: 'Cookies',
    component: CookieCompliance,
    meta: { elementId: 'page-cookies' },
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: PageNotFound,
    meta: { elementId: 'page-not-found' },
  },  
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
});

// https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
// https://router.vuejs.org/guide/advanced/meta.html
router.beforeEach((to, from, next) => {
  if (to.matched.some(route => route.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters['auth/isAuthenticated']) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

// https://router.vuejs.org/guide/advanced/navigation-guards.html#global-after-hooks
router.afterEach(() => {
  store.dispatch('search/updateAddressBarQueryString');
});

export default router;
