import { pickBy } from 'lodash';
import { DELIMITER } from './config';

const searchQueryStringBuilder = function (params) {
  
  // Quick search, molecular formula, DOI etc. (simple fields)
  const singleValueParams = pickBy(params, v => typeof(v.value) === 'string' && v.value.length);
  const singleParameterQueryString = singleValueQueryStringBuilder(singleValueParams);
  
  // UV maxima (Compound properties)
  const uvMaxParams = pickBy(params.uvMax, v => v.value.length);
  const uvMaxQueryString = uvMaxQueryStringBuilder(uvMaxParams);

  // 13C NMR data and 1H NMR data (NMR chemical shifts)
  const nmrParams = pickBy(params.carbonNmr.concat(params.protonNmr), v => v.value.length);
  const nmrQueryString = nmrQueryStringBuilder(nmrParams);

  // Taxonomy
  const taxonomyParams = params.taxonomy;
  const taxonomyQueryString = taxonomyQueryStringBuilder(taxonomyParams);

  // Authors (Bibliographic)
  const authorParams = pickBy(params.author, v => v.familyName.length);
  const authorQueryString = authorQueryStringBuilder(authorParams);

  // Categories (Bibliographic)
  const categoryParams = params.category;
  const categoryQueryString = categoryQueryStringBuilder(categoryParams);

  // Concatenate full query string...
  let fullQueryString = singleParameterQueryString
    + uvMaxQueryString
    + nmrQueryString
    + taxonomyQueryString
    + authorQueryString
    + categoryQueryString;

  // ...but strip first ampersand if present
  if (fullQueryString.charAt(0) === '&') {
    fullQueryString = fullQueryString.substring(1);
  }

  return fullQueryString;
}

const singleValueQueryStringBuilder = function (params) {
  const singleParameterQueryString = Object.keys(params).map((key) => {
    let queryStringParameter = `${key}=${encodeURIComponent(params[key].value)}`;
    if (key === 'exactMass') {
      queryStringParameter += encodeURIComponent(`${DELIMITER}${params[key].variance}`);
    }
    if (key === 'structure') {
      queryStringParameter += encodeURIComponent(`${DELIMITER}${params[key].searchType}${DELIMITER}${params[key][params[key].searchType]}${DELIMITER}`);
      queryStringParameter += params[key].searchType === 'similarity' ? encodeURIComponent(params[key].threshold) : '';
    }
    return queryStringParameter;
  }).join('&');

  return singleParameterQueryString;
}

const uvMaxQueryStringBuilder = function (uvMax) {
  const uvMaxQueryString = Object.keys(uvMax).map((key) => {
    return '&uvMax=' + encodeURIComponent(`${uvMax[key].value}${DELIMITER}${uvMax[key].variance || 0}`);
  }).join('');
  
  return uvMaxQueryString.length ? `${uvMaxQueryString}` : '';
}

const nmrQueryStringBuilder = function (params) {
  
  // Split 13C and 1H into separate arrays
  const carbonNmrParams = pickBy(params, v => 'protons' in v);
  const protonNmrParams = pickBy(params, v => !('protons' in v));
  
  // 13C query string
  const carbonNmrQueryString = Object.keys(carbonNmrParams).map((key) => {
    return '&carbonNmr=' + encodeURIComponent(`${carbonNmrParams[key].value}${DELIMITER}${carbonNmrParams[key].variance || 0}${DELIMITER}${carbonNmrParams[key].protons}`);
  }).join('');

  // 1H query string
  const protonNmrQueryString = Object.keys(protonNmrParams).map((key) => {
    return '&protonNmr=' + encodeURIComponent(`${protonNmrParams[key].value}${DELIMITER}${protonNmrParams[key].variance || 0}`);
  }).join('');

  let combinedNmrQueryString = '';
  combinedNmrQueryString += carbonNmrQueryString.length ? `${carbonNmrQueryString}` : '';
  combinedNmrQueryString += protonNmrQueryString.length ? `${protonNmrQueryString}` : '';
  
  return combinedNmrQueryString.length ? combinedNmrQueryString : '';
}

const taxonomyQueryStringBuilder = function (taxonomies) {
  let queryStringParameter = [];
  for (const taxonomy of taxonomies)
  {
    queryStringParameter.push('&taxonomy=' + Object.keys(taxonomy).map((i) => {
      return taxonomy[i] !== null ? encodeURIComponent(taxonomy[i]) : '';
    }).join(encodeURIComponent(DELIMITER)));
  }
  const taxonomyQueryString = queryStringParameter.join('');
  return taxonomyQueryString.length ? `${taxonomyQueryString}` : '';
}

const authorQueryStringBuilder = function (authors) {
  const authorQueryString = Object.keys(authors).map((key) => {
    return '&author=' + encodeURIComponent(`${authors[key].familyName}${DELIMITER}${authors[key].firstName}`);
  }).join('');
  
  return authorQueryString.length ? `${authorQueryString}` : '';
}

const categoryQueryStringBuilder = function (categories) {
  const categoryQueryString = Object.keys(categories).map((key) => {
    return '&category=' + encodeURIComponent(`${categories[key].category}${DELIMITER}${categories[key].subcategory}`);
  }).join('');
  
  return categoryQueryString.length ? `${categoryQueryString}` : '';
}

const mapVisibleBoundsQueryStringBuilder = function (mapVisibleBounds) {
  return mapVisibleBounds
    ? encodeURIComponent(`${mapVisibleBounds.topLeft.lat}${DELIMITER}${mapVisibleBounds.topLeft.lon}${DELIMITER}${mapVisibleBounds.bottomRight.lat}${DELIMITER}${mapVisibleBounds.bottomRight.lon}`)
    : '';
}

export const apiHelper = {
  searchQueryStringBuilder,
  mapVisibleBoundsQueryStringBuilder,
};