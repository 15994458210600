<template>
  <div class="has-text-centered">
    <button
      type="button"
      id="btn-show-more"
      @click="loadMoreResults"
      :disabled="isLoading"
      :class="{ 'is-loading': isLoading }"
      class="button is-outlined is-large my-4">
      Show more...
    </button>
  </div>
</template>

<script>
export default {
  name: 'LoadMoreButton',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    loadMoreResults() {
      this.$emit('click');
    },
  },
}
</script>
