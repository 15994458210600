<template>
  <!-- Announces changes in search results to screen readers -->
  <div class="is-sr-only" aria-live="assertive" aria-atomic="true">
    Found {{ compounds ? compounds : 'no' }} {{ compounds === 1 ? 'compound' : 'compounds' }}
    and {{ articles ? articles : 'no' }} {{ articles === 1 ? 'article' : 'articles' }}
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'SearchAnnouncer',
  computed: {
    ...mapState('search', {
      articles: state => state.SearchResultCounts.articles,
      compounds: state => state.SearchResultCounts.compounds,
    }),
  },
}
</script>
