<template>
  <div id="search-form-results-buttons" class="columns is-marginless px-3 py-3">
    <div class="column">
      <button
        role="link"
        type="button"
        id="btn-show-compounds"
        class="button is-primary is-fullwidth"
        :class="{ 'is-loading': isLoading }"
        :disabled="compoundResultsCount === 0 && !isLoading"
        @click="showCompounds">
        {{ compoundButtonText }}
      </button>
      <p v-if="compoundSearchError" role="alert" class="has-text-danger has-text-centered mt-3">
        Sorry, that search didn't work
      </p>
    </div>
    <div class="column">
      <button
        role="link"
        type="button"
        id="btn-show-articles"
        class="button is-primary is-fullwidth"
        :class="{ 'is-loading': isLoading }"
        :disabled="articleResultsCount === 0 && !isLoading"
        @click="showArticles">
        {{ articleButtonText }}
      </button>
      <p v-if="articleSearchError" role="alert" class="has-text-danger has-text-centered mt-3">
        Sorry, that search didn't work
      </p>
    </div>
    <div class="column is-narrow has-text-centered">
      <button type="button" id="btn-search-panel-close" class="button" @click="closeSearchPanel" aria-label="Close search panel">Close</button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'ResultsButtons',
  computed: {
    compoundButtonText() {
      switch (this.compoundResultsCount) {
        case 0:
          return 'No compounds found';
        case 1:
          return 'Show 1 compound';
        default:
          return `Show ${this.compoundResultsCount.toLocaleString()} compounds`;
      }
    },
    articleButtonText() {
      switch (this.articleResultsCount) {
        case 0:
          return 'No articles found';
        case 1:
          return 'Show 1 article';
        default:
          return `Show ${this.articleResultsCount.toLocaleString()} articles`;
      }
    },
    isLoading() {
      return this.IsCompoundsLoading || this.IsArticlesLoading;
    },
    ...mapState('compounds', {
      IsCompoundsLoading: state => state.ServiceStatus.AwaitingResponse,
      compoundSearchError: state => state.ServiceStatus.HasError,
    }),
    ...mapState('articles', {
      IsArticlesLoading: state => state.ServiceStatus.AwaitingResponse,
      articleSearchError: state => state.ServiceStatus.HasError,
    }),
    ...mapState('search', {
      articleResultsCount: state => state.SearchResultCounts.articles,
      compoundResultsCount: state => state.SearchResultCounts.compounds,
    }),
  },
  methods: {
    closeSearchPanel() {
      this.$emit('closeSearchPanel');
    },
    showCompounds() {
      if (!this.isLoading) {
        this.$emit('closeSearchPanel');
        if (this.$route.name !== 'Compounds') this.$router.push('compounds');
      }
    },
    showArticles() {
      if (!this.isLoading) {
        this.$emit('closeSearchPanel');
        if (this.$route.name !== 'Articles') this.$router.push('articles');
      }
    },
  },
}
</script>
