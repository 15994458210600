<template>
  
  <!-- Column container for logo and auth -->
  <div class="rsc-header is-translucent">
    
    <!-- 1. LOGO / MOBILE SITE TITLE -->
    <div class="rsc-header__brand">
      
      <!-- RSC logo for desktop -->
      <a href="https://www.rsc.org/" id="header-link-rsc" class="rsc-header__link rsc-header__link--desktop">
        <img src="/rsc-logo-rev-230.png" alt="Royal Society of Chemistry homepage" width="144" height="36" />
      </a>
        
      <!-- RSC icon with site title/strapline for smaller viewports -->
      <router-link to="/" class="rsc-header__link rsc-header__link--touch" aria-label="MarinLit homepage">
        <div class="columns is-mobile is-vcentered pl-0">
          <div class="column is-narrow pt-2 pr-0">
            <img src="@/assets/rsc-icon-50x50.png" alt="Royal Society of Chemistry logo" width="40" height="40" class="mt-1" />
          </div>
          <div class="column is-narrow pt-2 pl-2">
            <div class="pl-3" style="border-left: 1px solid white;">
              <span class="is-size-5 has-text-weight-bold" style="line-height: 1;">MarinLit</span>
              <span class="is-block is-size-7" style="line-height: 1.2;">Dedicated to marine natural products research</span>
            </div>
          </div>
        </div>
      </router-link>
        
    </div>
    
    <!-- 2. AUTH STATUS / LINKS -->
    <div v-if="this.$route.name !== 'Login'" class="rsc-header__auth">
      <div id="header-auth-info" class="rsc-header__auth-content">
        <router-link v-if="!isAuthenticated" to="/login" id="header-link-login" class="rsc-header__link">
          Sign in
        </router-link>
        <a href v-else-if="isAuthenticated && !isAuthenticatedByIpAddress" @click.prevent="logout()" id="header-link-logout" class="rsc-header__link">
          Sign out
        </a>
        <div v-else-if="isAuthenticatedByIpAddress && loggedInAs" id="header-branding" class="rsc-header__auth-network">
          <svg xmlns="http://www.w3.org/2000/svg" class="rsc-header__auth-icon" height="28px" viewBox="0 0 24 24" width="28px" fill="#FFFFFF"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 4c1.93 0 5 1.4 5 5.15 0 2.16-1.72 4.67-5 7.32-3.28-2.65-5-5.17-5-7.32C7 5.4 10.07 4 12 4m0-2C8.73 2 5 4.46 5 9.15c0 3.12 2.33 6.41 7 9.85 4.67-3.44 7-6.73 7-9.85C19 4.46 15.27 2 12 2z"/><path d="M12 7c-1.1 0-2 .9-2 2s.9 2 2 2a2 2 0 1 0 0-4zM5 20h14v2H5v-2z"/></svg>
          <span class="rsc-header__auth-label">Access is provided by: <b class="rsc-header__auth-identity">{{ loggedInAs }}</b></span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'AuthHeader',
  methods: {
    ...mapActions('auth', [
      'logout',
    ]),
  },
  computed: {
    ...mapGetters('auth', [
      'isAuthenticated',
      'isAuthenticatedByIpAddress',
      'loggedInAs',
    ]),
  },
}
</script>
