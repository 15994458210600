import HelpBubble from '@/components/forms/help-bubble';
import TextInput from '@/components/forms/input-text';
import AddRemoveRowButtons from '@/components/forms/add-remove-row-buttons';
export default {
  props: {
    legend: {
      type: String,
      required: true,
      validator: value => { return value.length },
    },
  },
  methods: {
    updateSearch() {
      this.$emit('updateSearch');
    },
  },
  components: {
    HelpBubble,
    TextInput,
    AddRemoveRowButtons,
  },
};
